import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20')
];

export const server_loads = [];

export const dictionary = {
		"/(public)": [16,[4]],
		"/(private)/contact": [5,[2],[3]],
		"/(public)/forgot-password": [17,[4]],
		"/(public)/login": [~18,[4]],
		"/(private)/logout": [~6,[2],[3]],
		"/(private)/password-changed": [7,[2],[3]],
		"/(public)/password-reset": [~19,[4]],
		"/(private)/shop": [8,[2],[3]],
		"/(private)/shop/cart": [9,[2],[3]],
		"/(private)/shop/catalog/[[category]]": [~10,[2],[3]],
		"/(private)/shop/orders": [~11,[2],[3]],
		"/(private)/shop/orders/pending/[ref]": [~13,[2],[3]],
		"/(private)/shop/orders/[ref]": [~12,[2],[3]],
		"/(private)/shop/products/[code]": [~14,[2],[3]],
		"/(private)/shop/search": [15,[2],[3]],
		"/(public)/terms": [20,[4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';