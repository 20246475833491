import * as Sentry from "@sentry/sveltekit";
import type { HandleClientError } from "@sveltejs/kit";

// If you don't want to use Session Replay, remove the `Replay` integration,
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
Sentry.init({
  dsn: "https://23d38fa0f7a2365e83209595f36d8aa8@o4507208087961600.ingest.de.sentry.io/4507208116535376",
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
  integrations: [Sentry.replayIntegration()]
});

// This handler catches unexpected errors happening anywhere in the frontend.
// Reference: https://kit.svelte.dev/docs/hooks#shared-hooks-handleerror
// TODO: log to an external logging and notification service
export const handleError: HandleClientError = Sentry.handleErrorWithSentry(
  async ({ message }) => {
    console.error(message);
    return { message: "", requestId: "" };
  }
);

